body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (min-width: 700px) {
  .container-login {
    left: 50%;
  }
}

@media (min-width:330px) and (max-width: 700px) {
  .container-login {
    left: 10%;
  }
}

.container-login {
  width: 600px;
  height: 350px;
  position: absolute;
  top: 600%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  display: inline-flex;
}

.backbox {
  background-color: #4A4B4F;
  width: 100%;
  height: 80%;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  display: inline-flex;
}

.frontbox {
  background-color: white;
  border-radius: 20px;
  /* height: 100%; */
  width: 50%;
  z-index: 10;
  position: absolute;
  right: 0;
  margin-right: 3%;
  margin-left: 3%;
  transition: right .8s ease-in-out;
}

.moving {
  right: 45%;
}

.loginMsg,
.signupMsg {
  width: 50%;
  height: 100%;
  font-size: 15px;
  box-sizing: border-box;
}

.loginMsg .title,
.signupMsg .title {
  font-weight: 300;
  font-size: 23px;
}

.loginMsg p,
.signupMsg p {
  font-weight: 100;
}

.textcontent {
  color: white;
  margin-top: 25px;
  margin-left: 12%;
  margin-right: 12%;
}


/* front box content*/

.login,
.signup {
  padding: 20px;
  text-align: center;
}

.login h2,
.signup h2 {
  font-size: 22px;
}

.inputbox {
  margin-top: 8px;
}

.login input,
.signup input {
  display: block;
  width: 100%;
  height: 40px;
  background-color: #f2f2f2;
  border: none;
  margin-bottom: 20px;
}


/* Fade In & Out*/

.login p {
  cursor: pointer;
  color: #4A4B4F;
  font-size: 15px;
}

.loginMsg,
.signupMsg {
  opacity: 1;
  transition: opacity .8s ease-in-out;
}

.visibility {
  opacity: 0;
}

.hide {
  display: none;
}